import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Tooltip } from 'antd';
import { CustomInputWrapper, CustomFormItem, InputActions, BusinessSearchModal } from '../../components';
import { InputIds } from '../../tab_renderers/GeneralInformationTabRenderer/GeneralInformationTabRenderer';
import { InputModel } from '../../models';
import { InputWithoutSource } from '../../componentTypes';
import { SearchIcon } from '../../../custom_shared/icons';
import { useAppStore } from '../../stores';
import { BusinessDto } from '../../types';

const config = {
    label: 'Business ID'
};

const BusinessIdInput: InputWithoutSource = ({ input }) => {
    const { inputStore, businessSearchStore } = useAppStore();

    const onBusinessClick = (business: BusinessDto) => {
        input.setValue(business.Identifier);

        const expiringPeriodStartInput = inputStore.allInputs.find(i => i.id === InputIds.ExpiringPeriodStart);

        if (expiringPeriodStartInput) {
            expiringPeriodStartInput.setValue(business.InsuredPeriodStart);
        }

        businessSearchStore.setModalVisible(false);
    };

    return (
        <>
            <BusinessSearchModal onBusinessClick={onBusinessClick} />

            <CustomFormItem name={input.guid} required={input.required}>
                <CustomInputWrapper
                    input={input}
                    label={config.label}
                    allowClear
                    topRightNode={
                        <InputActions
                            input={input}
                            additionalLeftNode={
                                <Tooltip title="Business Search">
                                    <SearchIcon
                                        width={17}
                                        height={17}
                                        color="#000"
                                        onClick={() => businessSearchStore.setModalVisible(true)}
                                    />
                                </Tooltip>
                            }
                        />
                    }
                />
            </CustomFormItem>
        </>
    );
};

BusinessIdInput.meta = {
    inputType: InputModel.name
};

export default observer(BusinessIdInput);
