import * as React from 'react';
import { Form, Tooltip } from 'antd';
import { Rule, FormItemProps } from 'antd/lib/form';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { TypeUtils } from '../../../custom_shared/misc';
import { isEmpty } from 'lodash';
import './CustomFormItem.less';

type CustomProps = {
    children: React.ReactElement;
    required?: boolean;
    message?: string;
    messageStyle?: React.CSSProperties;
    customValidator?: (rule: unknown, value: unknown, setError: (error: string) => void) => Promise<void>
};

type Props = CustomProps & Omit<FormItemProps, 'rules'>;

const CustomFormItem: React.FC<Props> = props => {
    const {
        children,
        required,
        message = 'Please fill out this field',
        messageStyle,
        customValidator,
        ...formItemProps
    } = props;

    const [error, setError] = React.useState<string>('');

    const [tooltipVisible, setTooltipVisible] = React.useState<boolean>(false);

    const [isHovered, setIsHovered] = React.useState<boolean>(false);

    const valueIsValid = React.useCallback((value: unknown) => {
        if (TypeUtils.isNumber(value)) {
            return !isNaN(value) && isFinite(value);
        }

        if (TypeUtils.isString(value)) {
            return value.trim().length > 0;
        }

        return !isEmpty(value);
    }, []);

    const defaultValidator = React.useCallback(
        (_: unknown, value: unknown) => {
            if (!valueIsValid(value)) {
                setError(message);
                return Promise.reject(new Error(message));
            }

            setError('');
            return Promise.resolve();
        },
        [message, valueIsValid]
    );

    const formItemRules = React.useMemo(() => {
        const rules: Rule[] = [];

        if (required) {
            rules.push({ validator: defaultValidator });
        }

        if (customValidator) {
            rules.push({ validator: (rule: unknown, value: unknown) => customValidator(rule, value, setError) });
        }

        return rules;
    }, [required, defaultValidator, customValidator]);

    const getTitle = () => {
        if (!error) {
            return null;
        }

        return (
            <span>
                <ExclamationCircleOutlined className="custom-form-item-message-icon" />
                {error}
            </span>
        );
    };

    React.useEffect(() => {
        if (!formItemRules.length) {
            setError('');
        }
    }, [formItemRules]);

    React.useEffect(() => {
        if (isHovered && error) {
            setTooltipVisible(true);
        } else if (!isHovered) {
            setTooltipVisible(false);
        } else if (!error) {
            setTooltipVisible(false);
        }
    }, [error, isHovered]);

    const handleMouseEnter = () => setIsHovered(true);

    const handleMouseLeave = () => setIsHovered(false);

    const clonedChildren = React.cloneElement(children, {
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
        hasError: !!error,
        required
    });

    return (
        <Tooltip
            visible={tooltipVisible}
            placement="bottom"
            overlayClassName="custom-form-item-message"
            overlayInnerStyle={messageStyle}
            getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
            title={getTitle()}
        >
            <Form.Item className="custom-form-item" rules={formItemRules} {...formItemProps}>
                {clonedChildren}
            </Form.Item>
        </Tooltip>
    );
};

export default CustomFormItem;
